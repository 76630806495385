import createContext from "../createContext"

import greenhouseReducers from "./greenhouseReducers"
import * as greenhouseActions from "./greenhouseActions"

// import { Context as StepperContext } from "src/utils/context/stepper"

// const {
//   state: { step },
//   nextStep,
//   prevStep,
// } = useContext(StepperContext)

const initialValues = {
  jobs: null,
  jobCount: 0,
}

export const { Provider, Context } = createContext(
  greenhouseReducers,
  greenhouseActions,
  initialValues
)
