import React, { useReducer } from "react"

const createContext = (reducer, actions, initialState) => {
  const Context = React.createContext()
  const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const boundActions = {}

    for (let key in actions) {
      boundActions[key] = actions[key](dispatch, state)
    }

    return (
      <Context.Provider value={{ state, ...boundActions }}>
        {children}
      </Context.Provider>
    )
  }

  return { Provider, Context }
}

export default createContext

// Usage --- gatsby-ssr & gatsby-broswer

// import React from "react"
// import { Provider as AirtableProvider } from "src/utils/context/AirTableContext"

// export const wrapRootElement = ({ element }) => (
//   <AirtableProvider>{element}</AirtableProvider>
// )

// multiple providers...

// <ExampleProvider>
//   <ExampleProvider2>
//     {children}
//   </ExampleProvider2>
// </ExampleProvider>

// Coomponent

// import { Context as ExampleContext } from "../context/ExampleContext";
// const {state, signup} = useContext(ExampleContext)
