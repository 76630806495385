import axios from "axios"
import groupBy from "lodash/groupBy"

const getJobPosts = (dispatch) => async () => {
  try {
    const { data } = await axios.get(
      "https://boards-api.greenhouse.io/v1/boards/backboneai/jobs?content=true"
    )

    const groupedByDepartments = groupBy(
      data.jobs,
      ({ departments, location }) => {
        if (departments) {
          return departments[0].name
        }

        return location.name
      }
    )

    dispatch({
      type: "SET_JOB_POSTS",
      payload: {
        jobs: groupedByDepartments,
        jobCount: data.meta.total,
      },
    })
  } catch (err) {
    dispatch({
      type: "SET_JOB_POSTS",
      jobCount: 0,
    })
  }
}

export { getJobPosts }
