import produce from "immer"

const greenhouseReducers = (state, { payload, type }) => {
  switch (type) {
    case "SET_JOB_POSTS":
      return produce(state, (draft) => {
        draft.jobs = payload?.jobs
        draft.jobCount = payload?.jobCount
      })

    default:
      return state
  }
}

export default greenhouseReducers
